import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {  io,Socket } from 'socket.io-client'; 
import { SocketEvent } from 'src/app/shared/enums/socketEvent.enum';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket:any = undefined;
  public booking$ = new BehaviorSubject({});
  public user$ = new BehaviorSubject({});
  
  constructor() { }
  ngOninit() { }
  /**
   * Connects socket service
   */
  connect() {
  const userId=localStorage.getItem('admin_login_id');

  
    if (!this.socket) {
      if(userId){
        this.socket = io(`${environment.SOCKET_URL}?id=${userId}`); 
       
      }
    }
  } 
  /**
   * Disconnects socket service
   */
  disconnect() {
    if (this.socket) {
        this.socket.disconnect(); // Disconnect the socket
        this.socket = null; // Optionally set the socket to null to indicate it's disconnected
    } 
}
 
  /**
   * Gets socket
   * @returns  
   */
  public getSocket() {
    return this.socket;
  }
  /**
   * News user
   * @returns  
   */
  newUser() {
    this.socket?.on(SocketEvent.NEW_USER_UPDATE, (message:any) => {                     
      this.user$.next(message);
      console.log('new user',message);
      
  });
  return this.user$?.asObservable(); 
  }
  /**
   * News booking
   * @returns  
   */
  newBooking(eventNames: SocketEvent[]) {
    eventNames.forEach(eventName => {
      this.socket?.on(eventName, (message: any) => {
        this.booking$.next({ eventName, message });
        console.log('new booking', message);
        
      });
    });
    return this.booking$.asObservable(); 
  }

}
